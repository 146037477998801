import LoadingContainer from 'core-web/containers/LoadingContainer';
import ModuleContainer from 'core-web/containers/ModuleContainer';
import PropTypes from 'prop-types';
import React from 'react';
import DoubleCampaignModule from 'theme/components/campaigns/DoubleCampaignModule';
import HeaderSeparator from 'theme/components/HeaderSeparator';

const getProductIds = (content) => {
    const top = content.top_row
        .filter((item) => item.top_campaign_row.selected_card === 'product')
        .map((product) => product.top_campaign_row.product_data.product);
    const bottom = content.bottom_row
        .filter((item) => item.bottom_campaign_row.selected_card === 'product')
        .map((product) => product.bottom_campaign_row.product_data.product);

    return [...top, ...bottom];
};

const getArticleIds = (content) => {
    const top = content.top_row
        .filter((item) => item.top_campaign_row.selected_card === 'article')
        .map((article) => article.top_campaign_row.article_data.article);
    const bottom = content.bottom_row
        .filter((item) => item.bottom_campaign_row.selected_card === 'article')
        .map((article) => article.bottom_campaign_row.article_data.article);

    return [...top, ...bottom];
};

const Loading = (props) => <LoadingContainer {...props} success={(rest) => <DoubleCampaignModule {...rest} />} />;

const DoubleCampaign = ({ content = {} }) => {
    const productIds = getProductIds(content);
    const articleIds = getArticleIds(content);

    const header = content.header_data;
    const isTopRightWide = content.layout_select === 'right_wide';
    const isBottomRightWide = content.layout_select_copy === 'right_wide';

    const topRow = content.top_row.map((item, index, arr) => {
        if (
            arr.length === 2 ||
            (!isTopRightWide && index === 0 && arr.length < 4) ||
            (isTopRightWide && index === 2 && arr.length < 4)
        ) {
            item.isWide = true;
        }

        return item;
    });

    const bottomRow = (content.bottom_row || []).map((item, index, arr) => {
        if (
            arr.length === 2 ||
            (!isBottomRightWide && index === 0 && arr.length < 4) ||
            (isBottomRightWide && index === 2 && arr.length < 4)
        ) {
            item.isWide = true;
        }

        return item;
    });

    return topRow && bottomRow ? (
        <>
            <HeaderSeparator data={header} />
            <ModuleContainer
                productIds={productIds}
                articleIds={articleIds}
                articleAttributes={['external_id', 'hero_content', 'title', 'url']}
                render={Loading}
                renderProps={{ isTopRightWide, isBottomRightWide, modules: [topRow, bottomRow] }}
            />
        </>
    ) : null;
};

DoubleCampaign.propTypes = {
    content: PropTypes.object.isRequired,
};

export default DoubleCampaign;
