import AspectWrapper from 'core-web/components/AspectWrapper';
import BackgroundImage from 'core-web/components/BackgroundImage';
import Above from 'core-web/components/Breakpoints/Above';
import Below from 'core-web/components/Breakpoints/Below';
import Link from 'core-web/components/Link';
import decodeHTMLEntities from 'core-web/util/decodeHTMLEntities';
import findLongestWord from 'core-web/util/findLongestWord';
import get from 'core-web/util/get';
import { above } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import H1 from 'theme/components/base/headings/H1';
import H2 from 'theme/components/base/headings/H2';
import H4 from 'theme/components/base/headings/H4';
import BigArrowIcon from 'theme/components/icons/BigArrowIcon';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    height: 100%;

    & > div .arrow {
        transition: transform 0.25s ease;
    }

    ${above.lg} {
        &:hover > div .arrow {
            transform: translateX(4px);
        }
    }
`;

const Wrapper = styled('div')`
    display: block;
    padding: 16px;
    text-align: center;
    text-decoration: none;
    margin: auto;

    &[data-textshadow='true'] {
        h1,
        h2,
        h4,
        p {
            text-shadow: 2px 3px 5px black;
        }
    }
`;

const ArrowWrapper = styled('div')`
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 8px 8px 0;

    ${above.lg} {
        margin: 0 12px 12px 0;
    }
`;

const Video = styled('video')`
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
`;

const Content = ({ data, isH1Heading, withoutImage, override, color, isInProductGrid, handleTracking, textShadow }) => {
    const { elements, video } = override;
    const { t } = useTranslation();

    const heading = data.title;
    const linkUrl = data.url || {};

    const desktopVideo = elements && elements.includes('video') && video.desktop_video.url;
    const mobileVideo = elements && elements.includes('video') && video.mobile_video.url;

    const { loop, muted, poster, autoplay, controls } =
        elements && elements.includes('video') && video.settings ? video.settings : {};

    let setSizes = {};
    if (heading) {
        const longest = findLongestWord(heading);
        const large = longest >= 12;
        const medium = longest >= 8 && longest < 12;
        const small = longest <= 7;

        let fontSize = '20px';
        let lineHeight = '28px';

        if (large) {
            fontSize = '20px';
            lineHeight = '28px';
        } else if (medium) {
            fontSize = '24px';
            lineHeight = '32px';
        } else if (small) {
            fontSize = '32px';
            lineHeight = '40px';
        }

        setSizes = {
            fontSize,
            lineHeight,
        };
    }

    return (
        <StyledLink backgroundColor={withoutImage && color} to={linkUrl} onClick={() => handleTracking(heading)}>
            <Wrapper data-textshadow={textShadow}>
                <H4
                    as={isInProductGrid ? 'p' : H4}
                    mb="8px"
                    color={colors.white}
                    fontFamily="RobotoCondensed"
                    fontWeight="500"
                    fontSize="15px"
                    zIndex="10"
                >
                    {t('article.magazine')}
                </H4>
                {heading &&
                    (isH1Heading ? (
                        <H1
                            as={isInProductGrid ? 'p' : H1}
                            fontWeight="700"
                            color={colors.white}
                            lineHeight="40px"
                            zIndex="10"
                        >
                            {decodeHTMLEntities(heading)}
                        </H1>
                    ) : (
                        <H2
                            as={isInProductGrid ? 'p' : H2}
                            fontWeight="700"
                            color={colors.white}
                            lineHeight={[setSizes.lineHeight, null, '40px']}
                            fontSize={[setSizes.fontSize, null, '32px']}
                            zIndex="10"
                        >
                            {decodeHTMLEntities(heading)}
                        </H2>
                    ))}
                {linkUrl && (
                    <ArrowWrapper className="arrow" zIndex="10">
                        <BigArrowIcon width="31px" height="24px" color={colors.white} direction="right" />
                    </ArrowWrapper>
                )}
                {mobileVideo && desktopVideo && (
                    <Video
                        playsInline
                        autoPlay={autoplay}
                        controls={controls}
                        loop={loop}
                        muted={muted}
                        poster={poster}
                    >
                        <Below breakpoint="lg" render={() => <source src={mobileVideo} type="video/mp4" />} />
                        <Above breakpoint="lg" render={() => <source src={desktopVideo} type="video/mp4" />} />
                    </Video>
                )}
            </Wrapper>
        </StyledLink>
    );
};

Content.propTypes = {
    color: PropTypes.string,
    data: PropTypes.object.isRequired,
    handleTracking: PropTypes.func,
    isH1Heading: PropTypes.bool.isRequired,
    isInProductGrid: PropTypes.bool,
    override: PropTypes.object.isRequired,
    withoutImage: PropTypes.bool,
};

Content.defaultProps = {
    color: '',
    handleTracking: () => null,
    isInProductGrid: false,
    withoutImage: false,
};

const ArticleCard = ({
    auto,
    loading,
    data,
    isInProductGrid,
    isWide,
    isHero,
    isH1Heading,
    override,
    handleTracking,
}) => {
    if (!data) {
        return null;
    }
    const { elements, image, text_shadow: textShadow } = override;

    let imageSize = get(data, 'hero_content.image.image_size');
    let imagePosition = get(data, 'hero_content.image.image_position');
    let mobileImage = get(data, 'hero_content.image.mobile_image.url');
    let desktopImage = get(data, 'hero_content.image.desktop_image.url');
    let desktopMeta = get(data, 'hero_content.image.desktop_image.meta');

    if (image && elements.includes('image')) {
        mobileImage = (image.mobile_image || {}).url || mobileImage;
        desktopImage = (image.desktop_image || {}).url || desktopImage;
        imageSize = image.image_size;
        imagePosition = image.image_position;
        desktopMeta = (image.desktop_image || {}).meta || desktopMeta;
    }

    const color = !mobileImage && !desktopImage ? colors.green : null;

    let mobileRatio = 205 / 360;

    if (isHero) {
        // eslint-disable-next-line sonarjs/no-identical-expressions -- FIXME: can this be written as just 1?
        mobileRatio = 418 / 418;
    } else if (isWide) {
        mobileRatio = 418 / 360;
    }

    const tabletRatio = isWide ? 418 / 360 : 205 / 360;
    const desktopRatio = isWide ? 696 / 460 : 340 / 460;

    return (
        <AspectWrapper ratio={[mobileRatio, tabletRatio, desktopRatio]} position="relative">
            {mobileImage || desktopImage ? (
                <BackgroundImage
                    src={[mobileImage || desktopImage, null, null, desktopImage || mobileImage]}
                    query={{
                        auto,
                        w: isWide ? [418, null, null, 696] : [205, null, null, desktopMeta.ratio > 1.25 ? 500 : 340],
                        bg: color,
                    }}
                    backgroundColor={color}
                    backgroundPosition={imagePosition}
                    backgroundSize={imageSize}
                    loading={loading}
                >
                    <Content
                        data={data ?? null}
                        isH1Heading={isH1Heading}
                        isWide={isWide}
                        isInProductGrid={isInProductGrid}
                        override={override}
                        handleTracking={handleTracking}
                        textShadow={textShadow}
                    />
                </BackgroundImage>
            ) : (
                <Content
                    withoutImage
                    data={data}
                    isH1Heading={isH1Heading}
                    isWide={isWide}
                    isInProductGrid={isInProductGrid}
                    override={override}
                    color={color}
                    handleTracking={handleTracking}
                    textShadow={textShadow}
                />
            )}
        </AspectWrapper>
    );
};

ArticleCard.propTypes = {
    auto: PropTypes.string,
    data: PropTypes.object.isRequired,
    handleTracking: PropTypes.func,
    isH1Heading: PropTypes.bool,
    isHero: PropTypes.bool,
    isInProductGrid: PropTypes.bool,
    isWide: PropTypes.bool,
    loading: PropTypes.string,
    override: PropTypes.object.isRequired,
};

ArticleCard.defaultProps = {
    auto: null,
    handleTracking: () => null,
    isH1Heading: false,
    isHero: false,
    isInProductGrid: false,
    isWide: false,
    loading: 'lazy',
};

export default ArticleCard;
