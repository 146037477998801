import styled from 'theme/libs/styled';
import PropTypes from 'prop-types';
import colors from 'theme/config/colors';

const H4 = styled('h4')();

H4.propTypes = {
    color: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    fontFamily: PropTypes.string,
    fontSize: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    lineHeight: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    textTransform: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

H4.defaultProps = {
    color: colors.darkGrey,
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '1em',
};

export default H4;
